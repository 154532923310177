<template>

  <div class="m-head">
    <div class="m-head-father">
      <div class="head-container">
        <div class="logo" @click="$router.push('/m/home')"></div>
        <div class="more-icon" @click="isMore=true" v-if="!isMore">
          <svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.94971 11.9497H39.9497" stroke="#fff" stroke-width="4" stroke-linecap="square" stroke-linejoin="bevel" />
            <path d="M7.94971 23.9497H39.9497" stroke="#fff" stroke-width="4" stroke-linecap="square" stroke-linejoin="bevel" />
            <path d="M7.94971 35.9497H39.9497" stroke="#fff" stroke-width="4" stroke-linecap="square" stroke-linejoin="bevel" />
          </svg>
        </div>
        <div class="close-icon" @click="isMore=false" v-else>
          <svg width="20" height="20" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 14L34 34" stroke="#fff" stroke-width="4" stroke-linecap="square" stroke-linejoin="bevel" />
            <path d="M14 34L34 14" stroke="#fff" stroke-width="4" stroke-linecap="square" stroke-linejoin="bevel" />
          </svg>
        </div>
      </div>
      <div class="more" v-show="isMore">
          <ul>
            <li @click="toHome('mbSwiper')">
                <span>首页</span>
                <i class="el-icon-arrow-right"></i>
            </li>
            <li @click="toDigt('digtProd')">
                <span>数字产品</span>
                <i class="el-icon-arrow-right"></i>
            </li>
            <li @click="toIndustry('industry')">
                <span>工业服务</span>
                <i class="el-icon-arrow-right"></i>
            </li>
            <li @click="toAbout()">
                <span>关于我们</span>
                <i class="el-icon-arrow-right"></i>
            </li>
          </ul>
      </div>
    </div>
  </div>
</template>

<script>
import 'animate.css' 

export default {
    name:'mHeader',
  data() {
    return {
      isMore: false,
    }
  },
  methods:{
    // 跳转首页
    toHome(flag){
      this.$route.path === '/m/home'
       ? this.$router.replace('/m/home') 
       : this.$router.push('/m/home')

       this.isMore = !this.isMore
       
       setTimeout(()=>{
        this.$bus.$emit('scrollToMb', flag)
      }, 50)
    },
    // 跳转数字产品
    toDigt(flag) {
      this.$route.path === '/m/home'
       ? this.$router.replace('/m/home') 
       : this.$router.push('/m/home')

      this.isMore = !this.isMore
      setTimeout(()=>{
        this.$bus.$emit('scrollToMb', flag)
      }, 50)
    },

    toIndustry(flag){
      this.$route.path === '/m/home'
       ? this.$router.replace('/m/home') 
       : this.$router.push('/m/home')

      this.isMore = !this.isMore
      setTimeout(()=>{
        this.$bus.$emit('scrollToMb', flag)
      }, 50)
    },

    toAbout() {
      this.isMore = !this.isMore
      this.$router.push('/m/about')
    }
  }
}
</script>

<style scoped>
.m-head {
  width: 100%;
  z-index: 999;
  position: relative;
  height: 48px;
}

.m-head .m-head-father {
  top: 0;
  position: fixed;
  width: 100%;
  height: 48px;
  transform: translate3d(0, 0, 0);
  background: rgb(79, 209, 233);
}

.m-head .head-container {
  margin: 0 16px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.m-head .head-container .logo {
  width: 160px;
  height: 20px;
  background-image: url("~@/assets/images/logo.png");
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
}

.m-head .head-container i {
  display: inline-block;
  width: 32px;
  height: 32px;
  background-color: #fff;
}

.m-head .m-head-father .more {
  position: absolute;
  right: 0;
  top: 48px;
  width: 130px;
  background-color: rgb(79, 209, 233, 0.55);
  overflow: hidden;
}

.m-head .m-head-father .more-icon,
.m-head .m-head-father .close-icon {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.m-head .m-head-father .more h5 {
  font-size: 18px;
  height: 40px;
  line-height: 40px;
  color: #fff;
  text-align: center;
  padding: 16px;
}

.m-head .m-head-father .more ul {
    margin: 0 12px;
    margin-bottom: 0;
}

.m-head .m-head-father .more ul li {
  height: 44px;
  line-height: 44px;
  margin: 0 6px;
  font-size: 14px;
  border-bottom: 0.5px solid rgba(255, 255, 255, 0.5);
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

</style>