<template>
  <div class="footer">
    <div class="footer-container">
      <div class="link">
        <a @click="toHome('mbSwiper')">首页</a>
        <a @click="toDigt('digtProd')">数字产品</a>
        <a @click="toIndustry('industry')">工业服务</a>
        <a @click="toAbout">关于易代工</a>
      </div>
      <div class="logo">
        <img src="@/assets/images/logo.png" alt="">
      </div>
      <div class="bottom">
        <p>Copyright © 2019 eMakerSolutions Network Technology Co., Ltd. All rights reserved.</p>
        <p>地址：江苏省南京市江宁开发区苏源大道19号B4座二层</p>
        <p>京ICP备2018xxxx号</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'mFooter',
  methods:{

    toAbout(){
      this.$router.push('/m/about')
    },

    // 跳转首页
    toHome(flag){
      this.$route.path === '/m/home'
       ? this.$router.replace('/m/home') 
       : this.$router.push('/m/home')

       setTimeout(()=>{
        this.$bus.$emit('scrollToMb', flag)
      }, 50)
    },
 
    // 跳转数字产品
    toDigt(flag) {
      this.$route.path === '/m/home'
       ? this.$router.replace('/m/home') 
       : this.$router.push('/m/home')

      setTimeout(()=>{
        this.$bus.$emit('scrollToMb', flag)
      }, 50)
    },

    // 跳转工业服务
    toIndustry(flag){
      this.$route.path === '/m/home'
       ? this.$router.replace('/m/home') 
       : this.$router.push('/m/home')

      setTimeout(()=>{
        this.$bus.$emit('scrollToMb', flag)
      }, 50)
    },
  }
}
</script>

<style scoped>
.footer {
  background: rgb(79, 209, 233);
  overflow: hidden;
  padding: 30px 20px;
}


.footer .footer-container .link {
  display: flex;
  justify-content: space-between;
}

.footer .footer-container .link a {
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 12px;
}

.footer .footer-container .logo {
  text-align: center;
  margin-top: 16px;
}

.footer .footer-container .logo img {
  width: 32%;
}

.footer .footer-container .bottom {
  margin-top: 16px;
}
.footer .footer-container .bottom p {
  color: #fff;
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  margin: 0 12px;
  margin-top: 4px;
}
</style>