<template>
  <div class="mb-index">
    <Header/>
    <!-- 控制mobile的组件路由切换 -->
    <router-view></router-view>
    <Footer/>
  </div>
</template>

<script>
import Header from './components/Header.vue'
import Footer from './components/Footer.vue'

export default {
    name:'MbIndex',
    components:{ Header, Footer  },
    mounted(){
      this.scroll()
    },
    methods:{
      // 单页面点击滚动指定位置
    scroll() {
      this.$bus.$on('scrollToMb', (anchor) => {
        // 根据id获取dom节点
        const anchorEle = document.querySelector(`#${ anchor }`);
        let flag = !!anchorEle
        if (flag) {
          // 获取元素居中位置时候距离浏览器顶部高度
          let HIGT = ( document.documentElement.clientHeight - anchorEle.offsetHeight ) / 2

          // 将元素滚动指定位置
          anchorEle.scrollIntoView({
            block: "end",
            behavior: "smooth", 
          });

          // 将元素滚动视口居中位置
          window.scrollTo({
            left: 0, 
            top:anchorEle.offsetTop - HIGT, 
            behavior: "smooth"
          });
        }
      })
    }
    }
}
</script>

<style>

</style>